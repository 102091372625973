import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, isRef as _isRef, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-2 h-full" }
const _hoisted_2 = { class: "grid flex-1 gap-2 pt-4 overflow-y-auto scrollbar" }
const _hoisted_3 = { class: "absolute grid justify-center items-center h-full w-full" }

import draggable from 'vuedraggable';
	import { PinnedHubs, useDialog, useGlobal, useHubs } from '@/store/store';
	import { useToggleMenu } from '@/store/toggleGlobalMenu';
	import { ref } from 'vue';
	import { useI18n } from 'vue-i18n';

	// Components
	import HubMenuHubIcon from './HubMenuHubIcon.vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'HubMenu',
  props: {
		hubOrderingIsActive: Boolean,
	},
  setup(__props) {

	const global = useGlobal();
	const hubs = useHubs();
	const toggleMenu = useToggleMenu();
	const dialog = useDialog();
	const { t } = useI18n();
	const hoverOverHubremoval = ref(false);

	let backupPinnedHubs = [] as PinnedHubs;

	const props = __props;

	function sendToHub(event: Event) {
		if (props.hubOrderingIsActive) {
			event.preventDefault();
		} else {
			toggleMenu.hideMenuAndSendToHub();
		}
	}

	async function confirmationHubRemoval() {
		let removeHub = Boolean(await dialog.yesno(t('dialog.hub_unpin_title'), t('dialog.hub_unpin_context')));

		if (removeHub) {
			backupPinnedHubs.splice(0, backupPinnedHubs.length);
		} else {
			global.pinnedHubs = backupPinnedHubs.slice();
			backupPinnedHubs.splice(0, backupPinnedHubs.length);
		}
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(draggable), {
        onStart: _cache[0] || (_cache[0] = ($event: any) => (_isRef(backupPinnedHubs) //@ts-ignore
 ? backupPinnedHubs.value = _unref(global).pinnedHubs.slice() : backupPinnedHubs = _unref(global).pinnedHubs.slice())),
        onEnd: _cache[1] || (_cache[1] = ($event: any) => (hoverOverHubremoval.value = false)),
        list: _unref(global).pinnedHubs,
        "item-key": 'hubId',
        handle: ".handle",
        class: "flex flex-col gap-2 list-group",
        group: "hubs"
      }, {
        item: _withCtx(({ element }) => [
          (_unref(hubs).hub(element.hubId))
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["flex gap-1 justify-center", { handle: __props.hubOrderingIsActive }])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["hover:cursor-pointer flex flex-col gap-2 my-auto", { hidden: !__props.hubOrderingIsActive }])
                }, [
                  _createVNode(_component_Icon, {
                    type: "triangle",
                    size: "xs"
                  }),
                  _createVNode(_component_Icon, {
                    class: "rotate-180",
                    type: "triangle",
                    size: "xs"
                  })
                ], 2),
                _createVNode(_component_router_link, {
                  to: { name: 'hub', params: { name: element.hubName } }
                }, {
                  default: _withCtx(({ isActive }) => [
                    (_unref(global).loggedIn || element.hubId === _unref(hubs).currentHubId)
                      ? (_openBlock(), _createBlock(HubMenuHubIcon, {
                          key: 0,
                          class: "text-ph-text border",
                          hub: _unref(hubs).hub(element.hubId),
                          hubId: element.hubId,
                          active: isActive,
                          pinned: true,
                          hubOrderingIsActive: __props.hubOrderingIsActive,
                          onClick: sendToHub
                        }, null, 8, ["hub", "hubId", "active", "hubOrderingIsActive"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ], 2))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["list"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["relative h-14 max-h-0 overflow-hidden transition-all ease-in-out duration-300", { 'max-h-14': __props.hubOrderingIsActive }])
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Icon, {
          type: "unpin",
          size: "xl",
          class: _normalizeClass([hoverOverHubremoval.value ? 'text-red' : 'text-ph-accent-icon'])
        }, null, 8, ["class"])
      ]),
      _createVNode(_unref(draggable), {
        group: "hubs",
        onDragover: _cache[2] || (_cache[2] = ($event: any) => (hoverOverHubremoval.value = true)),
        onDragleave: _cache[3] || (_cache[3] = ($event: any) => (hoverOverHubremoval.value = false)),
        list: [],
        onChange: confirmationHubRemoval,
        "item-key": 'unpin',
        tag: "ul",
        class: "list-group h-full opacity-0"
      }, {
        item: _withCtx(({ element: trash }) => [
          _createElementVNode("li", null, _toDisplayString(trash), 1)
        ]),
        _: 1
      })
    ], 2)
  ]))
}
}

})