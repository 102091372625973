import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = {
  key: 0,
  class: "z-10 absolute -right-1 -top-1 group-hover:hidden"
}
const _hoisted_3 = { class: "z-10 absolute -right-1 -top-1" }
const _hoisted_4 = ["src"]

import { Hub } from '@/store/hubs';
	import Badge from '../../../../hub-client/src/components/elements/Badge.vue';
	import HubIcon from '../../../../hub-client/src/components/ui/HubIcon.vue';

	import { ref } from 'vue';
	import { useGlobal } from '@/store/global';
	import { useMessageBox } from '@/store/messagebox';
	import { useSettings, FeatureFlag } from '@/store/settings';

	type Props = {
		type?: string;
		size?: string;
		hub?: Hub;
		hubId: string;
		pinned?: boolean;
		pinnable?: boolean;
		active?: boolean;
		hubOrderingIsActive?: boolean;
	};

	
export default /*@__PURE__*/_defineComponent({
  __name: 'HubMenuHubIcon',
  props: {
    type: { default: 'circle' },
    size: { default: 'xl' },
    hub: { default: undefined },
    hubId: { default: '' },
    pinned: { type: Boolean, default: false },
    pinnable: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    hubOrderingIsActive: { type: Boolean, default: false }
  },
  emits: ['pin'],
  setup(__props: any, { emit: __emit }) {

	const global = useGlobal();
	const messagebox = useMessageBox();
	const settings = useSettings();

	const props = __props;

	const accessToken = ref<string | null>(global.getAccessToken(props.hubId));

	// When a user opens the hub for the first time on a device or in a browser, the accessToken is
	// only stored after the receivedMessage action with a message of type addAccessToken from
	// the messageBox is finished.
	messagebox.$onAction(({ name, args, after }) => {
		if (name === 'receivedMessage' && args[0].type === 'addAccessToken') {
			after(() => {
				accessToken.value = global.getAccessToken(props.hubId);
			});
		}
	});

	const emit = __emit;

	function pin() {
		emit('pin');
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_ctx.hub)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([{ 'border-[3px]': _ctx.active && !_ctx.hubOrderingIsActive }, "z-0 p-2 block group text-center w-14 h-14 cursor-pointer relative rounded-xl transition-all ease-in-out"]),
        title: _ctx.hub.name
      }, [
        (_ctx.hub && _ctx.hub.unreadMessages > 0 && !_ctx.hubOrderingIsActive && !_unref(settings).isFeatureEnabled(_unref(FeatureFlag).unreadCounter))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.hub.unreadMessages > 99)
                ? (_openBlock(), _createBlock(Badge, {
                    key: 0,
                    class: "text-xxs",
                    color: "ph"
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("99+")
                    ])),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(Badge, {
                    key: 1,
                    color: "ph"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.hub.unreadMessages), 1)
                    ]),
                    _: 1
                  }))
            ]))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createElementVNode("iframe", {
            src: _ctx.hub.url + '/miniclient.html?accessToken=' + accessToken.value,
            class: "w-7 h-7"
          }, null, 8, _hoisted_4)
        ], 512), [
          [_vShow, _ctx.hub && !_ctx.hubOrderingIsActive && accessToken.value && _unref(settings).isFeatureEnabled(_unref(FeatureFlag).unreadCounter)]
        ]),
        (_ctx.pinnable)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 1,
              type: "pin",
              class: "-rotate-45 text-ph-accent-icon-2 absolute -left-2 -top-2 hidden opacity-20 hover:opacity-100 group-hover:block",
              onClick: _withModifiers(pin, ["prevent"])
            }))
          : _createCommentVNode("", true),
        _createVNode(HubIcon, {
          "hub-name": _ctx.hub.name,
          "icon-url": _ctx.hub.iconUrlLight,
          "icon-url-dark": _ctx.hub.iconUrlDark,
          class: "rounded-md"
        }, null, 8, ["hub-name", "icon-url", "icon-url-dark"])
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})